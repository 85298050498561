import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 28,
  },
  path: {
    fill: '#7df3e1',
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 337.46 428.5"
    >
      <path d="M66.2541 88.5561C65.1535 88.5561 64.1391 87.9628 63.602 87.0048L54.0777 70.0731V85.5099C54.0777 87.1904 52.7152 88.5528 51.0344 88.5528C49.3536 88.5528 47.9878 87.1938 47.9878 85.5099V58.4549C47.9878 57.0694 48.9227 55.8595 50.262 55.5081C51.6013 55.1568 53.0069 55.7534 53.6865 56.96L66.2541 79.3014L78.8218 56.96C79.5014 55.7534 80.907 55.1568 82.2463 55.5081C83.5856 55.8595 84.5205 57.0694 84.5205 58.4516V76.2087C84.5205 77.8893 83.158 79.2516 81.4772 79.2516C79.7964 79.2516 78.4339 77.8893 78.4339 76.2087V70.0731L68.9095 87.0015C68.3692 87.9595 67.3548 88.5528 66.2574 88.5528L66.2541 88.5561Z" fill="white"/>
<path d="M81.4788 88.5543C80.6761 88.5543 79.89 88.2296 79.3261 87.6629C78.7589 87.0996 78.4339 86.3142 78.4339 85.5123C78.4339 85.3135 78.4538 85.1146 78.4936 84.9158C78.5301 84.7236 78.5898 84.5314 78.6661 84.3492C78.7424 84.1669 78.8352 83.988 78.948 83.8256C79.0608 83.6566 79.1835 83.5008 79.3261 83.3617C79.4655 83.2192 79.6213 83.0933 79.7872 82.9839C79.953 82.8712 80.1288 82.7818 80.3146 82.7022C80.497 82.6293 80.6894 82.5664 80.8818 82.5299C81.2732 82.4471 81.6812 82.4471 82.0726 82.5299C82.2649 82.5664 82.4573 82.6293 82.6398 82.7022C82.8255 82.7818 83.0013 82.8712 83.1671 82.9839C83.333 83.0966 83.4889 83.2192 83.6315 83.3617C83.7741 83.5042 83.9002 83.6566 84.0063 83.8256C84.1191 83.988 84.212 84.1669 84.2883 84.3492C84.3646 84.5314 84.4243 84.7236 84.4607 84.9158C84.5005 85.1113 84.5205 85.3102 84.5205 85.5123C84.5205 86.3142 84.1954 87.0963 83.6315 87.6629C83.4889 87.8054 83.333 87.9347 83.1671 88.0407C83.0013 88.1534 82.8255 88.2462 82.6398 88.3224C82.4573 88.4019 82.2649 88.4582 82.0726 88.4947C81.8769 88.5345 81.6778 88.5543 81.4755 88.5543H81.4788Z" fill="white"/>
<path d="M114.576 63.3066V68.7232H112.626V54.2419H116.901C117.817 54.2419 118.612 54.3497 119.286 54.5653C119.966 54.7741 120.528 55.0739 120.973 55.4646C121.418 55.8554 121.748 56.327 121.963 56.8794C122.186 57.4319 122.297 58.0483 122.297 58.7288C122.297 59.4025 122.179 60.0189 121.943 60.5781C121.707 61.1373 121.361 61.619 120.902 62.0232C120.451 62.4274 119.888 62.7441 119.215 62.9731C118.541 63.1954 117.77 63.3066 116.901 63.3066H114.576ZM114.576 61.7503H116.901C117.46 61.7503 117.952 61.6762 118.376 61.528C118.807 61.3798 119.168 61.1743 119.457 60.9116C119.747 60.6421 119.966 60.3221 120.114 59.9515C120.262 59.581 120.336 59.1734 120.336 58.7288C120.336 57.8058 120.05 57.0849 119.478 56.5662C118.912 56.0474 118.053 55.788 116.901 55.788H114.576V61.7503Z" fill="#9096AF"/>
<path d="M128.344 58.3245C129.092 58.3245 129.765 58.4492 130.365 58.6984C130.971 58.9477 131.483 59.3014 131.901 59.7595C132.325 60.2176 132.649 60.7735 132.871 61.427C133.1 62.0737 133.215 62.7979 133.215 63.5997C133.215 64.4081 133.1 65.1357 132.871 65.7825C132.649 66.4292 132.325 66.9817 131.901 67.4398C131.483 67.8979 130.971 68.2516 130.365 68.5009C129.765 68.7434 129.092 68.8647 128.344 68.8647C127.596 68.8647 126.919 68.7434 126.313 68.5009C125.713 68.2516 125.201 67.8979 124.776 67.4398C124.352 66.9817 124.025 66.4292 123.796 65.7825C123.567 65.1357 123.453 64.4081 123.453 63.5997C123.453 62.7979 123.567 62.0737 123.796 61.427C124.025 60.7735 124.352 60.2176 124.776 59.7595C125.201 59.3014 125.713 58.9477 126.313 58.6984C126.919 58.4492 127.596 58.3245 128.344 58.3245ZM128.344 67.46C129.354 67.46 130.109 67.1231 130.607 66.4494C131.106 65.769 131.355 64.8224 131.355 63.6098C131.355 62.3904 131.106 61.4404 130.607 60.76C130.109 60.0795 129.354 59.7393 128.344 59.7393C127.832 59.7393 127.387 59.8269 127.01 60.0021C126.633 60.1772 126.316 60.4299 126.06 60.76C125.811 61.0901 125.622 61.4977 125.494 61.9828C125.373 62.4611 125.312 63.0034 125.312 63.6098C125.312 64.2161 125.373 64.7584 125.494 65.2368C125.622 65.7151 125.811 66.1193 126.06 66.4494C126.316 66.7728 126.633 67.0221 127.01 67.1973C127.387 67.3724 127.832 67.46 128.344 67.46Z" fill="#9096AF"/>
<path d="M134.097 58.4862H135.512C135.66 58.4862 135.781 58.5233 135.876 58.5974C135.97 58.6715 136.034 58.7591 136.068 58.8601L138.028 65.449C138.082 65.6915 138.133 65.9273 138.18 66.1564C138.227 66.3787 138.267 66.6044 138.301 66.8335C138.355 66.6044 138.416 66.3787 138.483 66.1564C138.55 65.9273 138.621 65.6915 138.695 65.449L140.858 58.8197C140.891 58.7187 140.949 58.6344 141.03 58.5671C141.117 58.4997 141.225 58.466 141.353 58.466H142.131C142.266 58.466 142.377 58.4997 142.465 58.5671C142.552 58.6344 142.613 58.7187 142.647 58.8197L144.759 65.449C144.833 65.6848 144.897 65.9172 144.951 66.1463C145.011 66.3753 145.068 66.601 145.122 66.8233C145.156 66.601 145.2 66.3686 145.254 66.1261C145.308 65.8835 145.365 65.6578 145.426 65.449L147.426 58.8601C147.46 58.7523 147.524 58.6648 147.618 58.5974C147.713 58.5233 147.824 58.4862 147.952 58.4862H149.306L145.991 68.7232H144.567C144.391 68.7232 144.27 68.6087 144.203 68.3796L141.939 61.4371C141.885 61.2821 141.841 61.1272 141.808 60.9722C141.774 60.8105 141.74 60.6522 141.707 60.4972C141.673 60.6522 141.639 60.8105 141.606 60.9722C141.572 61.1339 141.528 61.2922 141.474 61.4472L139.18 68.3796C139.106 68.6087 138.968 68.7232 138.766 68.7232H137.412L134.097 58.4862Z" fill="#9096AF"/>
<path d="M154.958 58.3245C155.571 58.3245 156.137 58.429 156.655 58.6378C157.174 58.8399 157.622 59.1364 157.999 59.5271C158.377 59.9111 158.67 60.3894 158.879 60.9621C159.094 61.528 159.202 62.1748 159.202 62.9024C159.202 63.1853 159.172 63.374 159.111 63.4683C159.05 63.5626 158.936 63.6098 158.767 63.6098H151.956C151.97 64.2565 152.057 64.8191 152.219 65.2974C152.381 65.7757 152.603 66.1766 152.886 66.5C153.169 66.8166 153.506 67.0558 153.897 67.2175C154.287 67.3724 154.725 67.4499 155.21 67.4499C155.662 67.4499 156.049 67.3994 156.372 67.2983C156.703 67.1905 156.986 67.076 157.221 66.9547C157.457 66.8335 157.652 66.7223 157.807 66.6212C157.969 66.5134 158.107 66.4595 158.222 66.4595C158.37 66.4595 158.485 66.5168 158.565 66.6313L159.071 67.2882C158.848 67.5577 158.582 67.7935 158.272 67.9956C157.962 68.191 157.629 68.3527 157.272 68.4807C156.922 68.6087 156.558 68.703 156.18 68.7636C155.803 68.831 155.429 68.8647 155.059 68.8647C154.351 68.8647 153.698 68.7468 153.098 68.511C152.505 68.2684 151.99 67.9181 151.552 67.46C151.121 66.9951 150.784 66.4225 150.542 65.742C150.299 65.0616 150.178 64.2801 150.178 63.3975C150.178 62.6834 150.285 62.0164 150.501 61.3966C150.723 60.7768 151.04 60.2412 151.451 59.7898C151.862 59.3317 152.364 58.9747 152.957 58.7187C153.55 58.4559 154.217 58.3245 154.958 58.3245ZM154.998 59.6484C154.129 59.6484 153.445 59.901 152.947 60.4063C152.448 60.9048 152.138 61.5987 152.017 62.488H157.585C157.585 62.0703 157.528 61.6897 157.413 61.3461C157.299 60.9958 157.13 60.696 156.908 60.4467C156.686 60.1907 156.413 59.9953 156.089 59.8606C155.773 59.7191 155.409 59.6484 154.998 59.6484Z" fill="#9096AF"/>
<path d="M161.504 68.7232V58.4862H162.535C162.73 58.4862 162.865 58.5233 162.939 58.5974C163.013 58.6715 163.064 58.7995 163.091 58.9814L163.212 60.5781C163.563 59.864 163.994 59.3081 164.506 58.9107C165.024 58.5064 165.631 58.3043 166.325 58.3043C166.608 58.3043 166.864 58.338 167.093 58.4054C167.322 58.466 167.534 58.5536 167.729 58.6681L167.497 60.0122C167.45 60.1806 167.345 60.2648 167.184 60.2648C167.089 60.2648 166.945 60.2345 166.749 60.1739C166.554 60.1065 166.281 60.0728 165.931 60.0728C165.304 60.0728 164.779 60.2547 164.354 60.6185C163.936 60.9823 163.586 61.5112 163.303 62.2051V68.7232H161.504Z" fill="#9096AF"/>
<path d="M173.708 58.3245C174.321 58.3245 174.887 58.429 175.406 58.6378C175.925 58.8399 176.373 59.1364 176.75 59.5271C177.127 59.9111 177.42 60.3894 177.629 60.9621C177.845 61.528 177.953 62.1748 177.953 62.9024C177.953 63.1853 177.922 63.374 177.862 63.4683C177.801 63.5626 177.687 63.6098 177.518 63.6098H170.707C170.72 64.2565 170.808 64.8191 170.97 65.2974C171.131 65.7757 171.354 66.1766 171.637 66.5C171.92 66.8166 172.256 67.0558 172.647 67.2175C173.038 67.3724 173.476 67.4499 173.961 67.4499C174.412 67.4499 174.8 67.3994 175.123 67.2983C175.453 67.1905 175.736 67.076 175.972 66.9547C176.208 66.8335 176.403 66.7223 176.558 66.6212C176.72 66.5134 176.858 66.4595 176.972 66.4595C177.121 66.4595 177.235 66.5168 177.316 66.6313L177.821 67.2882C177.599 67.5577 177.333 67.7935 177.023 67.9956C176.713 68.191 176.38 68.3527 176.022 68.4807C175.672 68.6087 175.308 68.703 174.931 68.7636C174.554 68.831 174.18 68.8647 173.809 68.8647C173.102 68.8647 172.448 68.7468 171.849 68.511C171.256 68.2684 170.741 67.9181 170.303 67.46C169.872 66.9951 169.535 66.4225 169.292 65.742C169.05 65.0616 168.928 64.2801 168.928 63.3975C168.928 62.6834 169.036 62.0164 169.252 61.3966C169.474 60.7768 169.791 60.2412 170.202 59.7898C170.613 59.3317 171.115 58.9747 171.707 58.7187C172.3 58.4559 172.967 58.3245 173.708 58.3245ZM173.749 59.6484C172.88 59.6484 172.196 59.901 171.697 60.4063C171.199 60.9048 170.889 61.5987 170.768 62.488H176.336C176.336 62.0703 176.278 61.6897 176.164 61.3461C176.049 60.9958 175.881 60.696 175.659 60.4467C175.436 60.1907 175.163 59.9953 174.84 59.8606C174.523 59.7191 174.16 59.6484 173.749 59.6484Z" fill="#9096AF"/>
<path d="M187.46 68.7232C187.204 68.7232 187.043 68.5986 186.975 68.3493L186.813 67.1063C186.376 67.6385 185.874 68.0663 185.308 68.3897C184.749 68.7064 184.105 68.8647 183.378 68.8647C182.791 68.8647 182.259 68.7535 181.781 68.5312C181.303 68.3021 180.895 67.9686 180.558 67.5307C180.221 67.0928 179.962 66.5471 179.78 65.8936C179.598 65.2401 179.507 64.4889 179.507 63.6401C179.507 62.8855 179.608 62.1849 179.81 61.5381C180.012 60.8846 180.302 60.3187 180.679 59.8404C181.063 59.362 181.528 58.9881 182.074 58.7187C182.62 58.4424 183.239 58.3043 183.933 58.3043C184.56 58.3043 185.096 58.4121 185.54 58.6277C185.985 58.8366 186.382 59.133 186.733 59.517V53.8376H188.531V68.7232H187.46ZM183.974 67.4095C184.56 67.4095 185.072 67.2747 185.51 67.0052C185.955 66.7358 186.362 66.3551 186.733 65.8633V60.9116C186.403 60.4669 186.039 60.157 185.641 59.9819C185.251 59.8 184.816 59.709 184.338 59.709C183.381 59.709 182.647 60.0492 182.135 60.7297C181.623 61.4101 181.367 62.3802 181.367 63.6401C181.367 64.307 181.424 64.8797 181.538 65.358C181.653 65.8296 181.821 66.2204 182.044 66.5303C182.266 66.8335 182.539 67.0558 182.862 67.1973C183.186 67.3387 183.556 67.4095 183.974 67.4095Z" fill="#9096AF"/>
<path d="M195.513 68.7232V53.8376H197.322V59.9616C197.747 59.4698 198.232 59.0757 198.778 58.7793C199.33 58.4761 199.96 58.3245 200.667 58.3245C201.26 58.3245 201.796 58.4357 202.274 58.658C202.752 58.8803 203.16 59.2138 203.497 59.6585C203.834 60.0964 204.093 60.6421 204.275 61.2956C204.457 61.9423 204.548 62.6902 204.548 63.539C204.548 64.2936 204.447 64.9976 204.245 65.6511C204.043 66.2979 203.75 66.8604 203.366 67.3387C202.988 67.8103 202.523 68.1842 201.971 68.4605C201.425 68.7299 200.809 68.8647 200.122 68.8647C199.461 68.8647 198.899 68.7367 198.434 68.4807C197.976 68.2247 197.575 67.8676 197.231 67.4095L197.14 68.3392C197.087 68.5952 196.932 68.7232 196.676 68.7232H195.513ZM200.081 59.7595C199.495 59.7595 198.98 59.8943 198.535 60.1638C198.097 60.4332 197.693 60.8139 197.322 61.3057V66.2574C197.646 66.7021 198.003 67.0154 198.394 67.1973C198.791 67.3792 199.232 67.4701 199.717 67.4701C200.674 67.4701 201.408 67.1299 201.92 66.4494C202.432 65.769 202.688 64.7989 202.688 63.539C202.688 62.8721 202.628 62.2994 202.507 61.8211C202.392 61.3427 202.224 60.952 202.001 60.6488C201.779 60.3389 201.506 60.1132 201.183 59.9717C200.859 59.8303 200.492 59.7595 200.081 59.7595Z" fill="#9096AF"/>
<path d="M209.487 71.7448C209.427 71.8795 209.349 71.9873 209.255 72.0682C209.167 72.149 209.029 72.1894 208.841 72.1894H207.507L209.376 68.127L205.152 58.4862H206.708C206.863 58.4862 206.985 58.5266 207.072 58.6075C207.16 58.6816 207.224 58.7658 207.264 58.8601L210.003 65.3075C210.063 65.4557 210.114 65.6039 210.154 65.7522C210.202 65.9004 210.242 66.0519 210.276 66.2069C210.323 66.0519 210.37 65.9004 210.417 65.7522C210.464 65.6039 210.518 65.4524 210.579 65.2974L213.237 58.8601C213.277 58.7523 213.344 58.6648 213.439 58.5974C213.54 58.5233 213.648 58.4862 213.762 58.4862H215.197L209.487 71.7448Z" fill="#9096AF"/>
<path d="M119.963 84.5479C120.077 84.7635 120.182 84.9892 120.276 85.2249C120.377 85.454 120.475 85.6864 120.569 85.9222C120.663 85.6797 120.761 85.4405 120.862 85.2047C120.963 84.9689 121.071 84.7432 121.185 84.5277L125.511 76.4331C125.564 76.332 125.618 76.2512 125.672 76.1905C125.733 76.1299 125.797 76.0861 125.864 76.0592C125.938 76.0322 126.019 76.0154 126.107 76.0086C126.194 76.0019 126.299 75.9985 126.42 75.9985H128.471V90.6112H126.076V81.1726C126.076 80.9974 126.08 80.8054 126.087 80.5966C126.1 80.3877 126.117 80.1755 126.137 79.9599L121.721 88.2465C121.62 88.4352 121.489 88.5834 121.327 88.6912C121.165 88.7922 120.977 88.8428 120.761 88.8428H120.387C120.171 88.8428 119.983 88.7922 119.821 88.6912C119.659 88.5834 119.528 88.4352 119.427 88.2465L114.95 79.9296C114.977 80.1519 114.994 80.3709 115.001 80.5865C115.014 80.7953 115.021 80.9907 115.021 81.1726V90.6112H112.626V75.9985H114.677C114.799 75.9985 114.903 76.0019 114.991 76.0086C115.078 76.0154 115.156 76.0322 115.223 76.0592C115.297 76.0861 115.365 76.1299 115.425 76.1905C115.486 76.2512 115.543 76.332 115.597 76.4331L119.963 84.5479Z" fill="#4B506B"/>
<path d="M135.701 80.0812C136.476 80.0812 137.176 80.2058 137.803 80.4551C138.436 80.7044 138.975 81.0581 139.42 81.5162C139.864 81.9743 140.208 82.5335 140.45 83.1937C140.693 83.854 140.814 84.5917 140.814 85.4069C140.814 86.2288 140.693 86.9699 140.45 87.6301C140.208 88.2903 139.864 88.8529 139.42 89.3177C138.975 89.7826 138.436 90.1396 137.803 90.3889C137.176 90.6382 136.476 90.7628 135.701 90.7628C134.926 90.7628 134.222 90.6382 133.589 90.3889C132.955 90.1396 132.413 89.7826 131.962 89.3177C131.517 88.8529 131.17 88.2903 130.921 87.6301C130.678 86.9699 130.557 86.2288 130.557 85.4069C130.557 84.5917 130.678 83.854 130.921 83.1937C131.17 82.5335 131.517 81.9743 131.962 81.5162C132.413 81.0581 132.955 80.7044 133.589 80.4551C134.222 80.2058 134.926 80.0812 135.701 80.0812ZM135.701 88.8428C136.563 88.8428 137.2 88.5531 137.611 87.9737C138.028 87.3943 138.237 86.5454 138.237 85.4271C138.237 84.3087 138.028 83.4565 137.611 82.8703C137.2 82.2842 136.563 81.9912 135.701 81.9912C134.825 81.9912 134.178 82.2876 133.761 82.8805C133.343 83.4666 133.134 84.3154 133.134 85.4271C133.134 86.5387 133.343 87.3875 133.761 87.9737C134.178 88.5531 134.825 88.8428 135.701 88.8428Z" fill="#4B506B"/>
<path d="M142.762 90.6112V80.2429H144.288C144.611 80.2429 144.823 80.3945 144.924 80.6976L145.096 81.5162C145.305 81.3006 145.524 81.1052 145.753 80.9301C145.989 80.7549 146.235 80.6033 146.491 80.4753C146.753 80.3473 147.033 80.2496 147.33 80.1823C147.626 80.1149 147.949 80.0812 148.3 80.0812C148.866 80.0812 149.367 80.1789 149.805 80.3743C150.243 80.5629 150.607 80.8324 150.897 81.1827C151.193 81.5263 151.416 81.9406 151.564 82.4257C151.719 82.904 151.796 83.4329 151.796 84.0123V90.6112H149.3V84.0123C149.3 83.379 149.152 82.8906 148.855 82.547C148.566 82.1966 148.128 82.0215 147.542 82.0215C147.111 82.0215 146.706 82.1192 146.329 82.3145C145.952 82.5099 145.595 82.776 145.258 83.1129V90.6112H142.762Z" fill="#4B506B"/>
<path d="M157.655 90.7729C156.759 90.7729 156.068 90.5203 155.583 90.015C155.098 89.503 154.855 88.799 154.855 87.9029V82.1124H153.804C153.67 82.1124 153.552 82.0686 153.451 81.9811C153.356 81.8935 153.309 81.7621 153.309 81.5869V80.5966L154.977 80.3237L155.502 77.4942C155.529 77.3594 155.59 77.255 155.684 77.1809C155.785 77.1068 155.91 77.0697 156.058 77.0697H157.351V80.3338H160.08V82.1124H157.351V87.7311C157.351 88.0545 157.432 88.3072 157.594 88.4891C157.756 88.671 157.971 88.7619 158.241 88.7619C158.396 88.7619 158.524 88.7451 158.625 88.7114C158.732 88.671 158.823 88.6305 158.898 88.5901C158.978 88.5497 159.049 88.5126 159.11 88.479C159.17 88.4385 159.231 88.4183 159.292 88.4183C159.366 88.4183 159.426 88.4385 159.474 88.479C159.521 88.5126 159.571 88.5665 159.625 88.6406L160.373 89.8533C160.009 90.1565 159.591 90.3855 159.12 90.5405C158.648 90.6954 158.16 90.7729 157.655 90.7729Z" fill="#4B506B"/>
<path d="M170.19 90.6112H169.068C168.832 90.6112 168.647 90.5776 168.512 90.5102C168.378 90.4361 168.277 90.2912 168.209 90.0756L167.987 89.3379C167.724 89.5737 167.465 89.7826 167.209 89.9645C166.96 90.1396 166.7 90.2879 166.431 90.4091C166.161 90.5304 165.875 90.6213 165.572 90.682C165.269 90.7426 164.932 90.7729 164.561 90.7729C164.123 90.7729 163.719 90.7157 163.348 90.6011C162.978 90.4799 162.658 90.3013 162.388 90.0655C162.126 89.8297 161.92 89.5367 161.772 89.1863C161.624 88.836 161.55 88.4284 161.55 87.9636C161.55 87.5728 161.651 87.1888 161.853 86.8115C162.062 86.4275 162.405 86.0839 162.884 85.7808C163.362 85.4709 163.999 85.2148 164.794 85.0127C165.589 84.8106 166.576 84.6961 167.755 84.6691V84.0628C167.755 83.3689 167.606 82.8569 167.31 82.5268C167.02 82.1899 166.596 82.0215 166.037 82.0215C165.632 82.0215 165.295 82.0686 165.026 82.163C164.757 82.2573 164.521 82.3651 164.319 82.4863C164.123 82.6009 163.941 82.7053 163.773 82.7996C163.604 82.8939 163.419 82.9411 163.217 82.9411C163.049 82.9411 162.904 82.8973 162.783 82.8097C162.661 82.7221 162.564 82.6143 162.489 82.4863L162.035 81.688C163.227 80.5966 164.666 80.0509 166.35 80.0509C166.956 80.0509 167.495 80.1519 167.967 80.354C168.445 80.5494 168.849 80.8256 169.179 81.1827C169.51 81.533 169.759 81.9541 169.927 82.4459C170.102 82.9377 170.19 83.4767 170.19 84.0628V90.6112ZM165.339 89.055C165.595 89.055 165.831 89.0314 166.047 88.9842C166.262 88.9371 166.464 88.8663 166.653 88.772C166.848 88.6777 167.034 88.5632 167.209 88.4284C167.391 88.2869 167.573 88.1219 167.755 87.9333V86.185C167.027 86.2187 166.417 86.2827 165.925 86.377C165.44 86.4646 165.05 86.5791 164.753 86.7206C164.457 86.8621 164.244 87.0271 164.116 87.2158C163.995 87.4044 163.935 87.6099 163.935 87.8322C163.935 88.2701 164.063 88.5834 164.319 88.772C164.581 88.9607 164.922 89.055 165.339 89.055Z" fill="#4B506B"/>
    </svg>
  );
};

export default LogoIcon;
